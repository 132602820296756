<template>
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.8 9.1L8.813 8.113L5.6 11.319V0H4.2V11.319L0.987 8.106L0 9.1L4.9 14L9.8 9.1Z" fill="#E2E2E2"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowDownIcon'
}
</script>
