<template>
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4.9L0.987 5.887L4.2 2.681V14H5.6V2.681L8.813 5.894L9.8 4.9L4.9 0L0 4.9Z" fill="#E2E2E2"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowUpIcon'
}
</script>
