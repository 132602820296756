<template>
  <BlockEditBtns
    :up-disabled="isUpDisabled"
    :down-disabled="isDownDisabled"
    @edit-mode="toggleMode"
    @move-up="moveSection('up')"
    @move-down="moveSection('down')"
    @delete="deleteSection"
  />

  <div
    class="editable-block"
    :class="{ editable: isEditMode }"
    :contenteditable="isEditMode"
  >
    <h2
      v-if="type === 'title'"
      class="main-title"
      ref="title"
      style="margin: 20px 0;"
      v-html="text"
    >
    </h2>

    <h3
      v-if="type === 'subtitle'"
      class="main-subtitle"
      ref="subtitle"
      v-html="text"
    >
    </h3>

    <p
      v-if="type === 'bold-text'"
      class="home-text"
      ref="bold-text"
    >
      <b v-html="text"></b>
    </p>

    <p
      v-if="type === 'text'"
      class="home-text"
      ref="text"
      v-html="text"
    >
    </p>

    <p
      v-if="type === 'list'"
      class="home-list"
      ref="list"
      v-html="text"
    >
    </p>
  </div>
</template>

<script>
import BlockEditBtns from './BlockEditBtns'

export default {
  name: 'AdminHomeBlock',
  components: { BlockEditBtns },
  emits: ['change-text'],
  props: {
    text: {
      type: [String, Array],
      required: true
    },
    type: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isEditMode: false
    }
  },
  computed: {
    sectionIdx() {
      return this.$store.getters['data/home'].findIndex(item => item.id === this.id)
    },
    isUpDisabled() {
      return this.sectionIdx === 0
    },
    isDownDisabled() {
      return this.sectionIdx === this.$store.getters['data/home'].length - 1
    },
  },
  methods: {
    toggleMode(value) {
      if (this.isEditMode) {
        this.saveChanges()
      }
      this.isEditMode = value
    },
    saveChanges() {
      if (this.text !== this.$refs[this.type].innerHTML) {
        this.$store.dispatch('data/changeSection', {
          id: this.id,
          text: this.$refs[this.type].innerHTML
        })
      }
    },
    moveSection(type) {
      switch(type) {
        case 'up':
          if (!this.isUpDisabled) {
            this.$store.dispatch('data/moveSection', { type: 'up', id: this.id })
          }
          break
        case 'down':
          if (!this.isDownDisabled) {
            this.$store.dispatch('data/moveSection', { type: 'down', id: this.id })
          }
          break
      }
    },
    deleteSection() {
      this.$store.dispatch('data/deleteSection', this.id)
    }
  }
}
</script>
