<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8889 0H1.55556C0.692222 0 0 0.7 0 1.55556V12.4444C0 13.3 0.692222 14 1.55556 14H12.4444C13.3 14 14 13.3 14 12.4444V3.11111L10.8889 0ZM7 12.4444C5.70889 12.4444 4.66667 11.4022 4.66667 10.1111C4.66667 8.82 5.70889 7.77778 7 7.77778C8.29111 7.77778 9.33333 8.82 9.33333 10.1111C9.33333 11.4022 8.29111 12.4444 7 12.4444ZM9.33333 4.66667H1.55556V1.55556H9.33333V4.66667Z" fill="#E2E2E2"/>
  </svg>

</template>

<script>
export default {
  name: 'SaveIcon'
}
</script>
