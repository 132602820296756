<template>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 11.2895V13.9975H2.70796L10.6946 6.01083L7.98667 3.30287L0 11.2895ZM12.7888 3.91668C13.0704 3.63505 13.0704 3.18011 12.7888 2.89848L11.099 1.20872C10.8174 0.927091 10.3624 0.927091 10.0808 1.20872L8.75934 2.5302L11.4673 5.23816L12.7888 3.91668V3.91668Z" fill="#E2E2E2"/>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon'
}
</script>
