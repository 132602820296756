<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM9.75 7.15H3.25V5.85H9.75V7.15Z" fill="#E2E2E2"/>
  </svg>
</template>

<script>
export default {
  name: 'RemoveIcon'
}
</script>
