<template>
  <app-card v-if="!loading">
    <TheAdminHomeTitle @add="addModal = true"/>

    <AdminHomeBlock
      v-for="block in content"
      :key="block.id"
      :id="block.id"
      :type="block.type"
      :text="block.text"
    />

    <HomeModal
      :visible="addModal"
      @action="addSection($event)"
      @close="addModal = false"
    />
  </app-card>
</template>

<script>
import TheAdminHomeTitle from '../components/TheAdminHomeTitle'
import AdminHomeBlock from '../components/ui/AdminHomeBlock'
import HomeModal from '../components/ui/HomeModal'
import AppCard from '@/components/ui/AppCard'

export default {
  name: 'AdminHome',
  components: {
    TheAdminHomeTitle,
    AdminHomeBlock,
    HomeModal,
    AppCard
  },
  data () {
    return {
      addModal: false,
      loading: false
    }
  },
  computed: {
    content () {
      return this.$store.getters['data/home']
    }
  },
  methods: {
    addSection (data) {
      this.$store.dispatch('data/addSection', data)
      this.addModal = false
    }
  },
  async mounted () {
    if (this.content === null) {
      this.loading = true
      await this.$store.dispatch('data/getHome')
      this.loading = false
    }
  }
}
</script>
