<template>
  <div class="editable-block-buttons">
    <app-button
      type="square"
      :primary="edit"
      @click="toggleMode"
    >
      <EditIcon v-if="!edit" />
      <SaveIcon v-if="edit" />
    </app-button>

    <template v-if="edit">
      <app-button type="square" :disabled="upDisabled" @click="$emit('move-up')">
        <ArrowUpIcon />
      </app-button>

      <app-button type="square" :disabled="downDisabled" @click="$emit('move-down')">
        <ArrowDownIcon />
      </app-button>

      <app-button type="square" danger @click="$emit('delete')">
        <RemoveIcon />
      </app-button>
    </template>
  </div>
</template>

<script>
import AppButton from './AppButton'
import EditIcon from '../icons/EditIcon'
import RemoveIcon from '../icons/RemoveIcon'
import ArrowDownIcon from '../icons/ArrowDownIcon'
import ArrowUpIcon from '../icons/ArrowUpIcon'
import SaveIcon from '../icons/SaveIcon'

export default {
  name: 'BlockEditBtns',
  components: { AppButton, EditIcon, RemoveIcon, ArrowDownIcon, ArrowUpIcon, SaveIcon },
  emits: ['edit-mode', 'move-up', 'move-down', 'delete'],
  data() {
    return {
      edit: false
    }
  },
  props: {
    upDisabled: Boolean,
    downDisabled: Boolean
  },
  methods: {
    toggleMode() {
      this.edit = !this.edit
      this.$emit('edit-mode', this.edit)
    }
  }
 }
</script>
