<template>
  <app-modal
    title="Добавление секции"
    :visible="visible"
    action
    @close="$emit('close')"
    @action="add"
    :disabled="isBtnDisabled"
  >
    <div class="form-item">
      <label for="type">Тип блока</label>
      <select v-model="type" id="type">
        <option value="title">Заголовок</option>
        <option value="subtitle">Подзаголовок</option>
        <option value="text">Текст</option>
        <option value="bold-text">Жирный текст</option>
        <option value="list">Список</option>
      </select>
    </div>

    <div v-if="type === 'title' || type === 'subtitle'" class="form-item input">
      <label for="itemName">{{ blockTitle }}</label>
      <input type="text" id="itemName" v-model="text">
    </div>

    <div v-if="type === 'text' || type === 'bold-text'" class="form-item input">
      <label for="itemText">{{ blockTitle }}</label>
      <textarea id="itemText" v-model="text" class="h-100px" @keypress.enter.stop></textarea>
    </div>

    <template v-if="type === 'list'">
      <div class="form-item" v-for="(_, idx) in list" :key="idx">
        <label class="w-100 flex align-items-center justify-space-evenly">
          {{ idx + 1 }}
          <input style="margin-left: 10px;" type="text" v-model="list[idx]" @keypress.enter.stop>
          <app-button
            type="square"
            style="margin-left: 10px;"
            @click="list.splice(idx, 1)"
          >
            <RemoveIcon />
          </app-button>
        </label>
      </div>

      <app-button @click="list.push('')">
        <AddIcon style="margin-right: 10px;" />
        Добавить пункт
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/ui/AppModal'
import AppButton from '@/components/ui/AppButton'
import AddIcon from '@/components/icons/AddIcon'
import RemoveIcon from '@/components/icons/RemoveIcon'

export default {
  name: 'HomeModal',
  components: { AppModal, AppButton, AddIcon, RemoveIcon },
  emits: ['close', 'action'],
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      type: 'text',
      text: '',
      list: ['']
    }
  },
  computed: {
    blockTitle() {
      switch(this.type) {
        case 'text':
          return 'Текст';
        case 'title':
          return 'Заголовок';
        case 'subtitle':
          return 'Подзаголовок';
        case 'bold-text':
          return 'Жирный текст';
        case 'list':
          return 'Список';
      }
    },
    place() {
      return this.$store.getters['data/home'].length + 1
    },
    isBtnDisabled() {
      return ((this.list.includes('') || !this.list.length) && this.type === 'list')
        || (!this.text && this.type !== 'list')
    }
  },
  methods: {
    add() {
      this.$emit('action', {
        type: this.type,
        text: this.type === 'list' ? this.list.join('<br>') : this.text,
        place: this.place
      })
      this.type = 'text'
      this.text = ''
    }
  }
}
</script>
