<template>
  <teleport to="body">
    <transition name="scale-fade">
      <div
        v-if="visible"
        class="modal"
      >
        <div class="card modal__card" :style="{ marginTop: top, marginBottom: top }">
          <h1 class="modal__title">{{ title }}</h1>

          <slot></slot>

          <div class="card-item" style="margin-top: 1rem;">
            <app-button
              v-if="closeBtn"
              fill
              @click="close"
            >
              {{ action ? 'Отменить' : 'Закрыть' }}
            </app-button>

            <app-button
              v-if="action"
              type="primary"
              fill
              :disabled="disabled"
              @click="$emit('action')"
            >
              Сохранить
            </app-button>
          </div>
        </div>

        <div class="modal__overlay" @click="close"></div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import AppButton from '@/components/ui/AppButton'

export default {
  name: 'AppModal',
  components: { AppButton },
  emits: ['close', 'action'],
  props: {
    title: {
      type: String,
      required: true
    },
    visible: {
      required: true
    },
    closeBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    action: {
      type: Boolean,
      required: false,
      default: false
    },
    top: {
      type: String,
      required: false,
      default: '135px'
    },
    disabled: Boolean
  },

  keysPressed: {},

  methods: {
    close() {
      this.$emit('close')
    },
    modalAction() {
      this.$emit('action')
    },
    handleKeydown(e) {
      this.$options.keysPressed[e.key] = true
      if (this.visible && e.key === 'Escape') {
        this.close()
      } else if (this.visible && this.$options.keysPressed['Control'] && e.key === 'Enter') {
        this.modalAction()
      }
    },
    handleKeyup(e) {
      delete this.$options.keysPressed[e.key]
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown)
    document.addEventListener('keyup', this.handleKeyup)
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeydown)
    document.removeEventListener('keyup', this.handleKeyup)
  },
  watch: {
    visible: value => {
      if (value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  }
}
</script>
