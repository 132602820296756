<template>
  <button
    :class="['btn', type, { primary }, { danger }, { warning }]"
    :style="{ width: fill ? '100%' : '' }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    type: String,
    fill: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    }
  }
}
</script>
