<template>
  <div class="card-item card-item--table-title">
    <h2 class="table-title card-item">
      Редактирование главной
    </h2>

    <app-button
      type="primary"
      style="display:flex; align-items: center"
      @click="$emit('add')"
    >
      <AddIcon style="margin-right: 10px;"/>
      Добавить блок
    </app-button>
  </div>
</template>

<script>
import AppButton from '../components/ui/AppButton'
import AddIcon from '../components/icons/AddIcon'

export default {
  name: 'TheAdminHomeTitle',
  components: { AppButton, AddIcon },
  emits: ['add']
}
</script>
